import reused from './reused';
import variables from '../../variables';
import initReviewVisibilityToggle from '../pages/specialistSingle/reviewVisibilityToggle';

const
    ReviewsPaginationEl = (variables.windowWidth >= variables.breakpoints.md) ? '.js-specialist-single-reviews-pagination-fraction' : '.js-specialist-single-reviews-pagination',
    ReviewsPaginationType = (variables.windowWidth >= variables.breakpoints.md) ? 'fraction' : 'bullets'
;

export default [
  {
    target: '.js-specialist-single-reviews-carousel',
    apiOptions: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      on: {
        init(swiper) {
          initReviewVisibilityToggle()
        }
      },
      pagination: {
        el: ReviewsPaginationEl,
        type: ReviewsPaginationType
      },
      breakpoints: {
        1200: {
          slidesPerView: 2
        }
      }
    },
    customOptions: {
      navHolder: '.js-specialist-single-reviews-controls',
      counter: true
    }
  },
  reused.videoGalleryCarousel,
  reused.statisticsCarousel
]