import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import {initFooterAnimation} from './footerAnimation';
gsap.registerPlugin(ScrollTrigger);

export default function initCenterSingleAnimations() {
  gsap.timeline({
    scrollTrigger: {
      trigger: '.js-animations--center-single',
      markers: false,
      start: 'bottom 80%',
      end: `+=${window.innerHeight}`,
      scrub: true
    }
  })
    .fromTo('.js-animations--center-single-content', {
      yPercent: 0,
      autoAlpha: 1
    }, {
      yPercent: 15,
      autoAlpha: 0
    })
    .fromTo('.js-animations--center-single-image', {
      yPercent: 0,
      autoAlpha: 1
    }, {
      yPercent: 15,
      autoAlpha: 0
    }, 0);


    initFooterAnimation();
}