import reused from './reused';
import variables from "../../variables";

const licensesControlsSelector = `.js-about-licenses-controls${(variables.windowWidth < variables.breakpoints.lg) ? '-mobile' : ''}`;

export default [
  {
    target: '.js-founder-carousel',
    apiOptions: {
      slidesPerView: 1,
      spaceBetween: 10,
      scrollbar: {
        el: '.js-founder-carousel-scrollbar',
        dragSize: 'auto',
        draggable: false
      },
    },
    customOptions: {
      navHolder: '.js-founder-carousel-controls'
    }
  },
  {
    target: '.js-about-licenses-captions',
    apiOptions: {
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      thumbs: {
        swiper: {
          el: '.js-about-licenses-images',
          slidesPerView: 1,
          spaceBetween: 30,
          freeMode: false,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          breakpoints: {
            576: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 3,
              freeMode: true,
            }
          }
        },
      },
    },
    customOptions: {
      navHolder: licensesControlsSelector
    }
  },
  reused.videoGalleryCarousel,
  reused.statisticsCarousel,
  reused.instagramCarousel
]