import Page from '../../classes/Page';
import carouselsData from "../../carouselData/serviceSingle";
import initServiceSingleAnimations from "../../animations/serviceSingle";
import is from "is_js";

const serviceSinglePage = new Page({
  onInit() {
    if (!is.touchDevice()) initServiceSingleAnimations()
  },
  name: 'serviceSingle',
  rootElementId: 'js-service-single',
  carousels: carouselsData
});

export default serviceSinglePage;