import gsap from "gsap";
import variables from '../../variables';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export function initFooterAnimation() {
  gsap.timeline({
    scrollTrigger: {
      trigger: '.js-animations--footer',
      markers: false,
      start: 'top bottom',
      end: `+=${variables.dom.footer.offsetHeight}`,
      scrub: true
    }
  })
    .fromTo(variables.dom.footer, {
      xPercent: -15,
      autoAlpha: 0
    }, {
      xPercent: 0,
      autoAlpha: 1
    }, 0);
}