import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import {initFooterAnimation} from './footerAnimation';
gsap.registerPlugin(ScrollTrigger);

export default function initSpecialistSingleAnimations() {
  gsap.timeline({
    scrollTrigger: {
      trigger: '.js-animations--specialist-single',
      markers: false,
      start: 'bottom 80%',
      end: `+=${window.innerHeight}`,
      scrub: true
    }
  })
    .fromTo('.js-animations--specialist-single-content', {
      yPercent: 0,
      autoAlpha: 1
    }, {
      yPercent: 15,
      autoAlpha: 0
    })
    .fromTo('.js-animations--specialist-single-image', {
      yPercent: 0,
      autoAlpha: 1
    }, {
      yPercent: 15,
      autoAlpha: 0
    }, 0);


  initFooterAnimation();
}