import variables from '../../variables';
import {$events} from '../../helpers/events';
import YTPlayer from 'yt-player';
import {$dom} from '../../helpers/dom';
import {$data} from '../../helpers/data';
import {isElement, preventDefault} from '../../helpers/_utilities';
import is from 'is_js';
import ModalController from '../../ui/Modal';
import {$style} from '../../helpers/style';

export default function youtubeModal(
  modalController,
  id = 'js-modal--youtube',
  buttonSelector = '[data-youtube-video-id]',
  preloaderSelector = '.js-modal--youtube-preloader',
  userPlayerParams = {}
) {

  const
    rootModal = document.getElementById(id),
    playerFrame = document.getElementById(id + '--iframe'),
    defaultPlayerParams = {
      autoplay: false,
      annotations: false,
      related: false,
      controls: true
    },
    playerParams = $data.deepAssign(defaultPlayerParams, userPlayerParams)
  ;

  let currentVideoId = null, player = null;

  function openModal(event) {
    preventDefault(event);
    let videoId = this.dataset.youtubeVideoId;
    if (Boolean(videoId)) {
      modalController.open(id, {
        onEnd() {
          if (is.null(currentVideoId) || videoId !== currentVideoId) {
            currentVideoId = videoId;
            player.load(currentVideoId)
          }

          player.play();
        }
      });
    }
  }
  function closeModal() {
    player.pause()
  }

  return {
    init() {

      if (
        isElement(rootModal)
        &&
        isElement(playerFrame)
        &&
        isElement($dom.get(buttonSelector))) {
        currentVideoId = null;
        player = new YTPlayer(playerFrame, playerParams);
        player.on('cued', () => $dom.remove($dom.get(preloaderSelector, rootModal)));
        player.on('error', err => {
          $style.set(playerFrame, {
            padding: '10%',
            textAlign: 'center'
          });

          $dom.html(playerFrame, err);
        });

        $events.delegate
          .on('click tap', '[data-youtube-video-id]', openModal)
          .on(variables.customEventNames.modal.closed, '#'+id, closeModal)
        ;
      }

    },
    destroy() {
      currentVideoId = null;
      if (player instanceof YTPlayer) player.destroy();
      modalController.close();
      $events.delegate
        .off('click tap', '[data-youtube-video-id]', openModal)
        .off(variables.customEventNames.modal.closed, '#'+id, closeModal)
    }
  }
}