import {$dom} from '../../helpers/dom';
import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import variables from '../../variables';


gsap.registerPlugin(ScrollTrigger);

export default function batchAnimation(options = {
  targetsSelector: `.${variables.classNames.batchTarget}`,
  stagger: .15,
  duration: .5,
  batchShift: 100,
  batchMax: 3
}) {

  const {targetsSelector, stagger, duration, batchShift, batchMax} = options;
  const batchElements = () => $dom.getAll(targetsSelector);

  let scrollTriggerInstances = [], els;

  return {
    init() {

      els = batchElements();

      if (els.length > 0) {
        gsap.set(els, {
          y: batchShift,
          autoAlpha: 0
        });

        scrollTriggerInstances = ScrollTrigger.batch(els, {
          batchMax,
          duration,
          onEnter: batch => gsap.to(batch, {
            autoAlpha: 1,
            y: 0,
            stagger,
            overwrite: true
          }),
          // onLeave: batch => gsap.to(batch, {
            // autoAlpha: 0,
            // y: -batchShift,
            // overwrite: true
          // }),
          // onEnterBack: batch => gsap.to(batch, {
          //   autoAlpha: 1,
          //   y: 0,
          //   stagger,
          //   overwrite: true
          // }),
          // onLeaveBack: batch => gsap.to(batch, {
          //   autoAlpha: 0,
          //   y: batchShift,
          //   overwrite: true
          // })
        });
      }

      return scrollTriggerInstances
    },
    destroy() {
      els = batchElements();
      scrollTriggerInstances = scrollTriggerInstances.filter(instance => {
        instance.kill(true);
        return false;
      });
      gsap.set(els, {clearProps: 'y,opacity,visibility'});
    }
  }
}