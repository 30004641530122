import Page from '../../classes/Page';
import initCentersAnimations from '../../animations/centers';
import is from "is_js";

const centersPage = new Page({
  onInit() {
    if (!is.touchDevice()) initCentersAnimations();
  },
  name: 'centers',
  rootElementId: 'js-centers',
});

export default centersPage;