import reused from './reused';
import {$dom} from '../../helpers/dom';

let equipmentsDescriptionCarousel;

const {offsetsCarousel, videoGalleryCarousel, specialistsCarousel} = reused;

export default [
  {
    target: '.js-center-single-equipments-carousel-description',
    apiOptions: {
      allowTouchMove: false,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      scrollbar: {
        el: '.js-centerSingle-equipments-scrollbar',
        dragSize: 'auto',
        draggable: false
      },
      pagination: {
        el: '.js-centerSingle-equipments-pagination',
        type: 'fraction',
        renderFraction: function (currentClass, totalClass, index, total) {
          return `<span class="${currentClass}">${index}</span>` +
              '&nbsp;из&nbsp;' +
              `<span class="${totalClass}">${total}</span>`;
        },
      },
    }
  },
  {
    target: '.js-center-single-equipments-carousel',
    apiOptions: {
      spaceBetween: 10,
      on: {
        init() {
          equipmentsDescriptionCarousel = $dom.get('.js-center-single-equipments-carousel-description').swiper;
        },
        slideChange(swiper) {
          equipmentsDescriptionCarousel.slideTo(swiper.activeIndex)
        }
      }
    },
    customOptions: {
      navHolder: '.js-centerSingle-equipments-controls',
      lazy: true,
      counter: true
    }
  },
  offsetsCarousel,
  videoGalleryCarousel,
  specialistsCarousel
]