import Page from '../../classes/Page';
import carouselsData from '../../carouselData/centerSingle'
import initCenterSingleAnimations from '../../animations/centerSingle';
import is from 'is_js';

const centerSinglePage = new Page({
  onInit() {
    if (!is.touchDevice()) initCenterSingleAnimations()
  },
  batch: true,
  name: 'centerSingle',
  rootElementId: 'js-center-single',
  carousels: carouselsData
});

export default centerSinglePage;