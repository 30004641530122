import {$events} from '../../helpers/events';
import {preventDefault} from '../../helpers/_utilities';
import {$dom} from '../../helpers/dom';
import {$style} from '../../helpers/style';
import gsap from 'gsap';
import variables from '../../variables';

export default function toggleText(
    containerSelector = '.js-text-toggle',
    buttonSelector = '.js-text-toggle--button',
    contentSelector = '.js-text-toggle--content',
    switchableClassName = variables.classNames.active,
    duration = variables.carouselDefaultDuration / 1000 / 2
) {

    function closeAll() {
        $dom.callAll(containerSelector, container => {
            const
                content = $dom.get(contentSelector, container),
                button = $dom.get(buttonSelector, container)
            ;

            if ($dom.hasClass(content, switchableClassName)) {
                gsap.to(content, {
                    height: $dom.attr(content, 'data-default-height'),
                    duration,
                    onStart: () => {
                        $dom.removeClass(content, switchableClassName);
                        $dom.html(button, button.dataset.expandedText)
                    }
                })
            }
        });
    }

    return {
        init() {
            $dom.callAll(contentSelector, content => {
                $dom.attr(content, {'data-default-height': $style.get(content, 'height')});
            });

            $events.delegate.on('click tap', buttonSelector, function (event) {
                preventDefault(event);

                const
                  container = this.closest(containerSelector),
                  content = $dom.get(contentSelector, container)
                ;

                if (content === null) return false;

                if ($dom.hasClass(content, switchableClassName)) {
                    gsap.to(content, {
                        height: $dom.attr(content, 'data-default-height'),
                        duration,
                        onStart: () => {
                            $dom.removeClass(content, switchableClassName);
                            $dom.html(this, this.dataset.expandedText)
                        }
                    })
                } else {
                    gsap.to(content, {
                        height: 'auto',
                        duration,
                        onStart: () => {
                            $dom.addClass(content, switchableClassName);
                            $dom.html(this, this.dataset.collapsedText)
                        }
                    })
                }
            });

            $events.delegate.on('click tap', document, ({target}) => {
                if ($dom.getParent(target, containerSelector) === null) closeAll();
            });
        },
        closeAll
    }
}