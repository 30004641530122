import {$events} from '../../../helpers/events';
import {preventDefault} from '../../../helpers/_utilities';
import {$dom} from '../../../helpers/dom';
import {$style} from '../../../helpers/style';
import gsap from 'gsap';
import variables from '../../../variables';

export default function initReviewVisibilityToggle(
  buttonSelector = '.js-specialist-single-review-toggle--button',
  contentSelector = '.js-specialist-single-review-toggle--content',
  switchableClassName = variables.classNames.active,
  duration = variables.carouselDefaultDuration / 1000 / 2
) {

  const defaultHeight = $style.get(contentSelector, 'height');

  $events.delegate.on('click tap', buttonSelector, function (event) {
    preventDefault(event);

    const
      slide = this.closest('.swiper-slide'),
      content = $dom.get(contentSelector, slide)
    ;

    if ($dom.hasClass(content, switchableClassName)) {
      gsap.to(content, {
        height: defaultHeight,
        duration,
        onStart: () => {
          $dom.removeClass(content, switchableClassName);
          $dom.html(this, this.dataset.expandedText)
        }
      })
    } else {
      gsap.to(content, {
        height: 'auto',
        duration,
        onStart: () => {
          $dom.addClass(content, switchableClassName);
          $dom.html(this, this.dataset.collapsedText)
        }
      })
    }

    // gsap.to(content, {
    //   height: currentContentHeight !== defaultHeight ? defaultHeight : 'auto'
    // })

  })

}