import variables from '../../variables';
import reused from './reused';
import {$dom} from '../../helpers/dom';
import {sleep} from '../../helpers/_utilities';


export default [
  {
    target: '.js-home-intro-carousel--content',
    apiOptions: {
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      simulateTouch: false,
      autoplay: {
        delay: 6000
      },
      pagination: {
        el: '.js-home-intro-carousel-pagination',
        type: 'bullets'
      },
      speed: variables.carouselDefaultDuration,
      on: {
        init(swiper) {
          if (swiper.isBeginning && swiper.isEnd) {
            swiper.allowTouchMove = false;
            $dom.addClass($dom.get('.js-home-intro-carousel-pagination'), 'is-hide');
          }

          sleep(1000)
            .then(() => {
              $dom.addClass(this.slides, variables.classNames.lazyLoaded)
            })
        }
      }
    },
    customOptions: {
      navHolder: '.js-home-intro-carousel--controls',
      counter: true,
      lazy: true
    }
  },
  {
    target: '.js-home-clinics-carousel',
    apiOptions: {
      slidesPerView: 1,
      spaceBetween: 20,
      allowTouchMove: true,
      pagination: {
        el: '.js-home-clinics-carousel-pagination',
        type: 'bullets'
      },
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        1600: {
          slidesPerView: 3
        }
      },
      on: {
        init(swiper) {
          if (swiper.isBeginning && swiper.isEnd) {
            swiper.allowTouchMove = false;
            $dom.addClass($dom.get('.js-home-clinics-carousel-pagination'), 'is-hide');
          }
        }
      }
    }
  },
  reused.offsetsCarousel,
  reused.specialistsCarousel,
  reused.galleryCarousel,
  reused.instagramCarousel
]