import toggleText from "../components/toggleText";
import variables from "../../variables";

const controlsSelector = `.js-service-single-reviews-controls${(variables.windowWidth >= variables.breakpoints.lg) ? '' : '-mobile'}`;

export default [
    {
        target: '.js-service-single-reviews-carousel',
        apiOptions: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            pagination: {
                el: `${controlsSelector} .js-service-single-reviews-pagination`,
                type: 'fraction'
            },
            scrollbar: {
                el: `${controlsSelector} .js-service-single-reviews-scrollbar`,
                dragSize: 'auto',
                draggable: false
            },
            on: {
                slideChangeTransitionStart() {
                    toggleText().closeAll();
                }
            },
            breakpoints: {
                1200: {
                    slidesPerView: 2
                }
            }
        },
        customOptions: {
            navHolder: controlsSelector
        }
    },
    {
        target: '.js-service-single-steps-carousel',
        apiOptions: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 0,
            pagination: {
                el: '.js-service-single-steps-pagination',
                type: 'fraction'
            },
            scrollbar: {
                el: '.js-service-single-steps-scrollbar'
            }
        },
        customOptions: {
            navHolder: '.js-service-single-steps-controls'
        }
    }
]