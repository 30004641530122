import {$dom} from '../helpers/dom';
import homePage from './pages/home/';
import centerSinglePage from './pages/centerSingle/';
import centersPage from './pages/centers/';
import newsPage from './pages/news/';
import newsSinglePage from './pages/newsSingle/';
import specialistsPage from './pages/specialists/';
import specialistSinglePage from './pages/specialistSingle/';
import documentsPage from './pages/documents/'
import departmentPage from './pages/department/'
import articlesPage from "./pages/articles/";
import articleSinglePage from "./pages/articleSingle/";
import vacanciesPage from "./pages/vacancies/";
import aboutPage from "./pages/about/";
import contactsPage from "./pages/contacts/";
import serviceSinglePage from "./pages/serviceSingle/";
import partnersPage from "./pages/partners/";
import supervisionPage from "./pages/supervision/"
import infoPage from "./pages/info/";
import reviewsPage from "./pages/reviews/";
import offersPage from "./pages/offers/";
import offerSinglePage from "./pages/offerSingle/";
import mediaPage from "./pages/media/";
import technologiesPage from "./pages/technologies/";
import technologySinglePage from "./pages/technologySingle/";
import pricesPage from "./pages/prices/";
import errorPage from "./pages/error/";
import 'lightgallery.js';
import 'lg-thumbnail.js';

$dom.ready(() => {

  const currentPage = [
    homePage,
    centerSinglePage,
    specialistsPage,
    specialistSinglePage,
    centersPage,
    newsPage,
    newsSinglePage,
    documentsPage,
    departmentPage,
    articlesPage,
    articleSinglePage,
    vacanciesPage,
    aboutPage,
    contactsPage,
    serviceSinglePage,
    partnersPage,
    supervisionPage,
    infoPage,
    reviewsPage,
    offersPage,
    offerSinglePage,
    mediaPage,
    technologiesPage,
    technologySinglePage,
    pricesPage,
    errorPage
  ].filter(page => {
    if (page.exist) {
      window.currentPage = page;
      return true
    }
  })[0];

  currentPage && currentPage.init();
});