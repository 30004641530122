import {$dom} from '../../helpers/dom';
import CarouselController from './CarouselCreator';
import variables from '../../variables';
import {$style} from '../../helpers/style';
import {$events} from '../../helpers/events';
import {preventDefault} from '../../helpers/_utilities';
import dropdown from '../components/dropdown';
import tabs from '../components/tabs';
import {fileInput} from '../components/form';
import toggleText from '../components/toggleText';
import Waves from 'node-waves';
import smoothScrollbar from '../components/scrollbar';
import batchAnimation from '../animations/batch';
import Model from './Model';
import Header from './Header';
import Footer from './Footer';
import Menu from './Menu';
import is from 'is_js';
import {isElement, warn} from '../../helpers/_utilities';
import LazyLoad from "vanilla-lazyload";
import youtubeModal from '../components/youtubeModal';
import gsap from 'gsap';
import lightbox from '../components/lightbox';
import datepicker from '../components/datepicker'

export default class Page extends Model {

  constructor(options = {}) {

    super(options);

    this.exist = true;

    if (is.not.domNode(this.rootElement)) {
      if (this.options.debug) warn(`Instance Page with name "${this.name}" has no root element provided`, 'Page-class');
      this.exist = false;
      return;
    }

    this.defaults = {
      carousels: [],
      batch: true
    };

    this.options = Object.assign(this.defaults, options);

    this.carousels = this.options.carousels;
    this.CarouselController = new CarouselController();

    this.headerIndent = this.headerIndent.bind(this);
    this.header = new Header();
    this.footer = new Footer();
    this.menu = new Menu();
    this.dropdowns = dropdown();
    this.datepickers = datepicker();
    this.toggleText = toggleText();
    this.tabs = tabs();
    this.fileInputs = fileInput();
    this.youtubeModal = youtubeModal(window[variables.$EXTERNAL_API_NAME].ModalController);
    this.smoothScrollbar = smoothScrollbar();
    this.batchAnimations = batchAnimation();
    this.imagesLazyLoad = this.lazyLoadInit();

    this.imagesGallery = lightbox();
  }

  lazyLoadInit() {
    const $self = this;
    let lazyInstance = null;

    return {
      init() {
        lazyInstance = new LazyLoad({
          elements_selector: '.'+variables.classNames.lazyImage+'--static',
          class_loaded: variables.classNames.lazyLoaded,
          class_loading: variables.classNames.lazyLoading,
          class_entered: 'is-entered',
          data_src: 'lazy-src',
          data_bg: 'lazy-bg',
          threshold: 300,
          callback_loaded(el) {
            const nextEl = el.nextElementSibling;
            if (isElement(nextEl) && nextEl.classList.contains(variables.classNames.lazyPreloader)) $dom.remove(nextEl)
          }
        });

        return $self;
      },
      destroy() {
        if (lazyInstance instanceof LazyLoad) lazyInstance.destroy();
        return $self;
      },
      get instance() {
        return lazyInstance
      }
    }
  }

  headerIndent(setMode = true) {
    $dom.callAll(`.${variables.classNames.indentFromHeaderTarget}`, target => {
      $style.remove(target, 'paddingTop');
      if (setMode) {
        gsap.set(target, {
          paddingTop: variables.dom.header.offsetHeight + 'px',
        })
      }
    });

    return this;
  }

  initWaves(duration = variables.carouselDefaultDuration / 1.5) {
    Waves.attach('.button--waves', ['waves-button']);

    Waves.init({duration});

    return this;
  }

  checkMobile() {
    if (is.touchDevice()) $dom.addClass(document.body, variables.classNames.mobile);
    else $dom.removeClass(document.body, variables.classNames.mobile);

    return this;
  }

  init() {
    this.carousels = this.CarouselController.create(this.options.carousels);

    $events.resize.on(this.headerIndent);

    this.toggleText.init();
    this.dropdowns.init();
    this.datepickers.init();
    this.tabs.init();
    this.fileInputs.init();
    this.youtubeModal.init();
    this.imagesGallery.init();

    if (!is.touchDevice()) {
      this.smoothScrollbar.initAll();

      if (this.options.batch) {
        this.batchAnimations.init();

        $events.resize.on(() => {
          this.batchAnimations.destroy();
          this.batchAnimations.init();
        });
      }
    } else {
      const target = 'data-scroll-to';
      const {get, attr, offset} = $dom;

      $events.delegate.on('tap click', `[${target}]`, event => {
        preventDefault(event);

        const offsetTop = offset(get(attr(`[${target}]`, target))).top;

        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        });
      });
    }

    this
      .headerIndent()
      .imagesLazyLoad.init()
      .initWaves()
      .checkMobile()
      .header.init()
    ;

    this.footer.init();
    this.menu.init();

    super.init(this);
    return this;
  }

  destroy() {
    this.carousels = this.CarouselController.destroy();

    $events.resize.off(this.headerIndent);

    this.dropdowns.destroy();
    this.smoothScrollbar.destroy();
    this.youtubeModal.destroy();
    this.imagesGallery.destroy();
    if (this.options.batch) this.batchAnimations.destroy();

    this
      .headerIndent(false)
      .imagesLazyLoad.destroy()
      .header.destroy()
      .footer.destroy()
      .menu.destroy()
    ;

    super.init(this);
    return this;
  }
}