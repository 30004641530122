import Page from '../../classes/Page';
import carouselsData from '../../carouselData/specialistSingle'
import initSpecialistSingleAnimations from '../../animations/specialistSingle';
import is from "is_js";

const specialistSinglePage = new Page({
  onInit() {
    if (!is.touchDevice()) initSpecialistSingleAnimations();
  },
  name: 'specialistSingle',
  rootElementId: 'js-specialist-single',
  carousels: carouselsData
});

export default specialistSinglePage;