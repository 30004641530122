import {$dom} from '../../helpers/dom';
import gsap from 'gsap';
import is from 'is_js';
import variables from '../../variables';
import {setCarouselLeftOffset} from '../components/statistics';

const
    videoGalleryCarouselPaginationEl = is.touchDevice() ? '.js-video-gallery-pagination-fraction' : '.js-video-gallery-pagination',
    videoGalleryCarouselPaginationType = is.touchDevice() ? 'fraction' : 'bullets',
    specialistsCarouselControls = `.js-specialists-carousel-controls${(variables.windowWidth < variables.breakpoints.lg) ? '-mobile' : ''}`,
    galleryCarouselControls = `.js-gallery-carousel-controls${(variables.windowWidth < variables.breakpoints.lg) ? '-mobile' : ''}`
;


if (variables.windowWidth >= variables.breakpoints.xl) {
  setCarouselLeftOffset(document.querySelector('.js-statistics-carousel-container'))
}

const reused = {
  videoGalleryCarousel: {
    target: '.js-video-gallery-carousel',
    apiOptions: {
      spaceBetween: 10,
      allowTouchMove: true,
      scrollbar: {
        el: '.js-video-gallery-scrollbar',
        dragSize: 'auto',
        draggable: false
      },
      pagination: {
        el: videoGalleryCarouselPaginationEl,
        type: videoGalleryCarouselPaginationType,
        bulletClass: 'videoCarousel__bullet',
        bulletActiveClass: 'videoCarousel__bullet--active',
        clickable: true,
        renderBullet: function (index, className) {
          return `<li class="${className}"><span>${index + 1}</span></li>`
        },
        renderFraction: function (currentClass, totalClass, index, total) {
          return `<span class="${currentClass}">${index}</span>` +
              '&nbsp;из&nbsp;' +
              `<span class="${totalClass}">${total}</span>`;
        },
      },
      on: {
        init(swiper) {
          if (swiper.slides.length < 2) {
            swiper.allowTouchMove = false;
            $dom.addClass($dom.get('.videoCarousel__bullets'), 'is-hide');
          }
        },
        lazyImageReady(swiper, slideEl) {
          const btn = $dom.get('.js-video-play--button', slideEl);
          gsap.to(btn, {
            scale: 1,
            duration: variables.carouselDefaultDuration / 1000 / 2,
            onStart() {
              gsap.set(btn, {transition: 'none'})
            },
            onComplete() {
              gsap.set(btn, {clearProps: 'transition'})
            },
          })

        }
      }
    },
    customOptions: {
      lazy: true,
      navHolder: '.js-video-gallery-controls'
    }
  },
  galleryCarousel: {
    target: '.js-gallery-carousel',
    apiOptions: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      pagination: {
        el: `${galleryCarouselControls} .js-gallery-carousel-pagination`,
        type: (variables.windowWidth < variables.breakpoints.lg) ? 'bullets' : 'fraction'
      },
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 3
        }
      }
    },
    customOptions: {
      navHolder: galleryCarouselControls,
      lazy: true
    }
  },
  instagramCarousel: {
    target: '.js-instagram-carousel',
    apiOptions: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      pagination: {
        el: '.js-instagram-carousel-pagination',
        type: 'bullets'
      },
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 3
        }
      }
    },
    customOptions: {
      navHolder: '.js-instagram-carousel-controls',
      lazy: true
    }
  },
  offsetsCarousel: {
    target: '.js-offers-carousel',
    apiOptions: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      pagination: {
        el: '.js-offers-carousel-pagination',
        type: 'fraction',
        renderFraction: function (currentClass, totalClass, index, total) {
          return `<span class="${currentClass}">${index}</span>` +
              '&nbsp;из&nbsp;' +
              `<span class="${totalClass}">${total}</span>`;
        },
      },
      scrollbar: {
        el: '.js-offers-carousel-scrollbar',
        dragSize: 'auto',
        draggable: false
      },
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 3
        }
      }
    },
    customOptions: {
      navHolder: '.js-offers-controls',
      lazy: true,
    }
  },
  statisticsCarousel: {
    target: '.js-statistics-carousel',
    apiOptions: {
      slidesPerView: 1,
      spaceBetween: 0,
      pagination: {
        el: '.js-statistics-carousel-pagination',
        type: 'bullets'
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        1200: {
          slidesPerView: 'auto',
          spaceBetween: 120
        }
      }
    }
  },
  servicesCarousel: {
    target: '.js-services-carousel',
    apiOptions: {
      slidesPerView: 1,
      spaceBetween: 30,
      pagination: {
        el: '.js-services-carousel-pagination',
        type: 'fraction'
      },
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        1200: {
          slidesPerView: 3
        }
      }
    },
    customOptions: {
      navHolder: '.js-services-carousel-controls',
    }
  },
  specialistsCarousel: {
    target: '.js-specialists-carousel',
    apiOptions: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      pagination: {
        el: `${specialistsCarouselControls} .js-specialists-carousel-pagination`,
        type: (variables.windowWidth < variables.breakpoints.lg) ? 'bullets' : 'fraction'
      },
      on: {
        init() {
        }
      },
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        1200: {
          slidesPerView: 3
        }
      }
    },
    customOptions: {
      navHolder: specialistsCarouselControls
    }
  }
};

export default reused;