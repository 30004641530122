import variables from '../../variables';
import {$dom} from '../../helpers/dom';
import {$events} from '../../helpers/events';
import gsap from "gsap";

export default function tabs() {
    const
        tabRootClassName = '.js-tabs',
        targets = $dom.getAll(tabRootClassName)
    ;


    const
        controlAttrName = 'data-tabs-control',
        controlSelector = `[${controlAttrName}]`,

        contentAttrName = 'data-tabs-content',
        contentSelector = `[${contentAttrName}]`
    ;

    const _filterByAttr = (elsArray, targetAttr, compareAttr) => {

        let outputObj = {
            active: null,
            other: []
        };

        elsArray.forEach(el => {
            if (el.getAttribute(targetAttr) === compareAttr) {
                outputObj.active = el;
            } else {
                outputObj.other.push(el)
            }
        });

        return outputObj
    };

    const _getItemsByAttr = (attr, tabsRoot) => {

        let
            controlElements = $dom.getAll(controlSelector, tabsRoot),
            contentElements = $dom.getAll(contentSelector, tabsRoot)
        ;

        return {
            controls: _filterByAttr(controlElements, controlAttrName, attr),
            content: _filterByAttr(contentElements, contentAttrName, attr)
        }
    };

    const _switchClassName = (target, action) => {
        if (Boolean(target)) {

            if (target instanceof Element) {
                target.classList[`${action}`](variables.classNames.active)
            } else if (typeof target === 'object' && target.length > 1) {
                target.forEach(el => el.classList[`${action}`](variables.classNames.active));
            }

        }
    };

    const changeTab = (anchor, tabsRoot) => {

        const {
            controls,
            content
        } = _getItemsByAttr(anchor, tabsRoot);

        _switchClassName(controls.other, 'remove');
        _switchClassName(content.other, 'remove');

        _switchClassName(controls.active, 'add');
        _switchClassName(content.active, 'add');

        if(content.other.length > 0) {
            gsap.to(content.other, {
                height: 0,
                autoAlpha: 0,
                duration: .4
            });
        }

        if(Boolean(content.active)) {
            gsap.to(content.active, {
                height: 'auto',
                autoAlpha: 1,
                duration: .4
            });
        }

    };

    return {
        init() {
            $dom.each(targets, tabsRoot => {
                const content = $dom.getAll(contentSelector, tabsRoot);
                const startTab = tabsRoot.dataset.tabsInitTab || content[0].dataset.tabsContent;

                if (content.length > 0) {

                    gsap.set(content, {overflow: 'hidden'});

                    changeTab(startTab, tabsRoot);

                    const tabsSelect = $dom.getAll('.js-tabs-select', tabsRoot);

                    if (tabsSelect.exist) {

                        const controlElements = $dom.getAll(controlSelector, tabsRoot);

                        controlElements.forEach((control, index) => {
                            if (control.getAttribute(controlAttrName) === startTab) {
                                tabsSelect.el.value = tabsSelect.el.options[index].value
                            }
                        })


                    }


                    const defineTabChangeSource = event => {
                        const {target} = event;
                        let btn;

                        if (event.type === 'change') {
                            btn = target.closest(tabRootClassName).querySelectorAll(controlSelector)[target.selectedIndex];
                        } else {
                            btn = target.closest(controlSelector);
                        }

                        if (btn) changeTab(btn.getAttribute(controlAttrName), tabsRoot);
                    };

                    $events.delegate.on('tap click change', tabsRoot, defineTabChangeSource);
                }
            })
        }
    }
}