import {$events} from '../../helpers/events';
import {$dom} from '../../helpers/dom';
import gsap from 'gsap';
import {$data} from '../../helpers/data';
import variables from '../../variables';

export default class Header {

  constructor(options = {}) {

    this.defaults = {
      selectors: {
        dropdown: {
          root: '.js-header-dropdown',
          button: '.js-header-dropdown-button',
          content: '.js-header-dropdown-content',
        }
      },
      dropdown: {
        toggleDuration: variables.carouselDefaultDuration / 1000 / 2
      }
    };

    this.options = $data.deepAssign(this.defaults, options);

    this.centersChoiceDropdown = this.initCentersChoiceDropdown();

  }

  initCentersChoiceDropdown() {
    const
      $self = this,
      {get, hasClass, removeClass, addClass} = $dom,
      dropdown = get($self.options.selectors.dropdown.root),
      content = get($self.options.selectors.dropdown.content, dropdown)
    ;

    function toggle() {
      if (hasClass(dropdown, variables.classNames.active)) {
        gsap.to(content, {
          height: 0,
          duration: $self.options.dropdown.toggleDuration / 2,
          onStart: () => {
            removeClass(dropdown, variables.classNames.active)
          }
        })
      } else {
        gsap.to(content, {
          height: 'auto',
          duration: $self.options.dropdown.toggleDuration,
          onStart: () => {
            addClass(dropdown, variables.classNames.active)
          }
        })
      }
    }

    function closeByClickOutside(event) {
      const {target} = (event || event.originalEvent);

      if (
        $dom.hasClass(dropdown, variables.classNames.active)
        &&
        !target.closest($self.options.selectors.dropdown.root)
      ) {
        toggle()
      }
    }

    return {
      init() {
        $events.delegate
          .on('click tap', $self.options.selectors.dropdown.button, toggle)
          .on('click tap', document, closeByClickOutside)
      },
      destroy() {
        $events.delegate
          .off('click tap', $self.options.selectors.dropdown.button, toggle)
          .off('click tap', document, closeByClickOutside)
      }
    }
  }

  destroy() {
    this.centersChoiceDropdown.destroy();
  }

  init() {
    this.centersChoiceDropdown.init();
  }

}
