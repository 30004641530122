import {$dom} from '../../helpers/dom';
import {Datepicker} from 'vanillajs-datepicker';
import ru from 'vanillajs-datepicker/js/i18n/locales/ru';

Object.assign(Datepicker.locales, ru);

export default function datepicker() {
  const
    datepickerSelector = '[data-datepicker]',
    containerSelector = '[data-datepicker-container]'
  ;

  return {
    init() {
      $dom.callAll(datepickerSelector, (datepicker, index) => {
        const container = $dom.getParent(datepicker, containerSelector);

        $dom.attr(container, {'data-datepicker-id': index});

        const DatepickerInstance = new Datepicker(datepicker, {
          container: `${containerSelector}[data-datepicker-id="${index}"]`,
          format: 'dd.mm.yyyy',
          language: 'ru',
          minDate: 'today',
          todayHighlight: true,
          autohide: true,
          prevArrow: '<svg class="svg-icon"><use xlink:href="img/sprite.svg#arrow-left"></use></svg>',
          nextArrow: '<svg class="svg-icon"><use xlink:href="img/sprite.svg#arrow-right"></use></svg>',
        });
      });
    }
  }
}