import gsap from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger';
import {initFooterAnimation} from './footerAnimation';
gsap.registerPlugin(ScrollTrigger);

export default function initServiceSingleAnimations() {
    gsap.timeline({
        scrollTrigger: {
            trigger: '.js-animations--service-single',
            markers: false,
            start: 0,
            end: `+=${window.innerHeight}`,
            scrub: true
        }
    })
        .fromTo('.js-animations--service-single-content', {
            yPercent: 0,
            autoAlpha: 1
        }, {
            yPercent: 15,
            autoAlpha: 0
        })
        .fromTo('.js-animations--service-single-image', {
            yPercent: 0,
            autoAlpha: 1
        }, {
            yPercent: 15,
            autoAlpha: 0
        }, 0);


    initFooterAnimation();
}