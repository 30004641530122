import {$dom} from '../../helpers/dom';
import {$style} from '../../helpers/style';

const bootstrapContainer = $dom.get('.container');

function setCarouselLeftOffset (container) {
  $style.remove(container, 'paddingLeft');

  $style.set(container, {
    paddingLeft: $dom.offset(bootstrapContainer).left + $style.get(bootstrapContainer, 'padding-left', true) + 'px'
  });

}

export {setCarouselLeftOffset};
