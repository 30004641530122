import variables from '../../variables';
import {preventDefault} from '../../helpers/_utilities';
import {$dom} from '../../helpers/dom';
import {$style} from '../../helpers/style';
import {$events} from '../../helpers/events';

export function fileInput() {
  const container = '.js-file-field';
  const fileNameSelector = '.js-file-name';

  function handleFiles() {
    const
      fileList = this.files,
      nameContainer = $dom.get(fileNameSelector, this.closest(container))
    ;

    let nameString = (fileList.length === 0) ? $dom.attr(nameContainer, 'data-default-text') : '';


    $dom.each(Array.from(fileList), file => {
      nameString += `<span>${file.name}</span>`;
    });

    $dom.html(nameContainer, nameString);
  }

  return {
    init() {
      $dom.callAll(`${container} input[type="file"]`, input => {
        input.addEventListener('change', handleFiles, false);
      });
    }
  }
}