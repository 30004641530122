import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import {$dom} from '../../helpers/dom';

gsap.registerPlugin(ScrollTrigger);

export default function initCentersAnimations() {

  $dom.callAll('.js-animations--centers-item', (item, index) => {

    let
      isEven = (index + 1) % 2 === 0,
      shift = 100
    ;

    gsap.timeline({
      scrollTrigger: {
        trigger: item,
        scrub: true,
        markers: false,
        start: "center bottom",
        end: `+=${window.innerHeight - item.offsetHeight}`
      }
    })
      .from(item, {
        autoAlpha: 0,
        x: isEven ? shift : -shift,
      })
      .from($dom.get('.js-animations--centers-item-content', item), {
        x: isEven ? shift / 2 : -shift / 2,
        autoAlpha: 0
      })
  })

}