import Page from '../../classes/Page';
import createContactsMap from '../../components/map';

window.FrontAPI = {};
window.FrontAPI.createContactsMap = createContactsMap;

const contactsPage = new Page({
  name: 'contacts',
  rootElementId: 'js-contacts'
});

export default contactsPage;