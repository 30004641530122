import {$events} from '../../helpers/events';
import {$dom} from '../../helpers/dom';
import gsap from 'gsap';
import {$data} from '../../helpers/data';
import variables from '../../variables';
import is from 'is_js';
import {$ui} from '../../helpers/ui';
import Scrollbar from 'smooth-scrollbar';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class Menu {

  constructor(options = {}) {

    this.defaults = {
      selectors: {
        root: '.js-menu',
        button: '.js-menu-button',
        menuHeader: '.js-menu-header',
        content: '.js-menu-content',
      },
      toggleDuration: variables.carouselDefaultDuration / 1000 / 2
    };

    this.options = $data.deepAssign(this.defaults, options);
    this.slideMenu = this.slideToggle();
  }

  slideToggle() {
    const
      $self = this,
      {get, hasClass, removeClass, addClass} = $dom,
      menu = get($self.options.selectors.root),
      content = get($self.options.selectors.content, $self.options.selectors.root)
    ;

    function toggle() {
      (hasClass(menu, variables.classNames.active)) ? hide() : show();
    }

    function hide() {
      gsap.to(menu, {
        height: 0,
        duration: $self.options.toggleDuration / 2,
        onStart: () => {
          removeClass(menu, variables.classNames.active);
          Scrollbar.destroy(content);
          if (is.touchDevice()) $ui.blockScroll('disable');
        }
      })
    }

    function show() {
      gsap.to(menu, {
        height: '100%',
        duration: $self.options.toggleDuration,
        onStart: () => {
          gsap.set(content, {autoAlpha: 0});
          addClass(menu, variables.classNames.active);
        },
        onComplete: () => {
          if (!is.touchDevice()) {
            Scrollbar.init(content);
            Scrollbar.detachStyle();
          } else {
            const menuHeader = get($self.options.selectors.menuHeader, menu);

            gsap.set(content, { height: 'auto', marginTop: menuHeader.clientHeight + 10 });
            gsap.set(menu, { overflow: 'auto' });

            addClass(menuHeader, variables.classNames.fixed);

            $ui.blockScroll('enable');
          }
          gsap.to(content, {autoAlpha: 1, duration: $self.options.toggleDuration});
        }
      })
    }

    return {
      init() {
        $events.delegate.on('click tap', $self.options.selectors.button, toggle)
      },
      destroy() {
        $events.delegate.off('click tap', $self.options.selectors.button, toggle)
      },
      slideUp() {
        hide();
      },
      slideDown() {
        show();
      }
    }
  }

  destroy() {
    this.slideMenu.destroy();
  }

  init() {
    this.slideMenu.init();
  }

  slideUp() {
    this.slideMenu.slideUp();
  }

  slideDown() {
    this.slideMenu.slideDown();
  }

}