import Page from '../../classes/Page';
import carouselsData from '../../carouselData/home'
import initHomeAnimations from '../../animations/home';
import is from 'is_js';

const homePage = new Page({
  onInit() {
    if (!is.touchDevice()) initHomeAnimations();
  },
  batch: true,
  name: 'home',
  rootElementId: 'js-home',
  carousels: carouselsData
});

export default homePage;