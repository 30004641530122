import variables from '../../variables';
import {$data} from '../../helpers/data';
import is from 'is_js';
import {$dom} from '../../helpers/dom';
import {Autoplay, EffectCoverflow, EffectFade, Lazy, Navigation, Swiper, Pagination, Scrollbar, Thumbs} from 'swiper';
import toggleText from '../components/toggleText';

Swiper.use([Autoplay, Navigation, EffectFade, Lazy, EffectCoverflow, Pagination, Scrollbar, Thumbs]);
const {deepAssign} = $data;

export default class CarouselController {
  constructor() {

    this.defaults = {
      slidesPerView: 1,
      spaceBetween: 30,
      slideActiveClass: variables.classNames.active,
      speed: variables.carouselDefaultDuration,
      navigation: {
        disabledClass: variables.classNames.disabled
      }
    };

    this.carousels = [];

  }

  counterUpdater(swiper) {

    const rootElem = swiper.$el[0].closest('[data-carousel-counter]');

    if (rootElem) {
      let current = $dom.get('[data-carousel-counter--current]', rootElem);
      let total = $dom.get('[data-carousel-counter--total]', rootElem);

      const update = () => {
        $dom.text(current, (swiper.activeIndex + (swiper.params.slidesPerGroup || 0)));
        $dom.text(total, swiper.slides.length);
      };

      update();

      return update
    }

    return false;
  }

  create(carousels) {
    const creator = this;

    if(is.array(carousels)) {
      this.carousels = carousels.map(carousel => {
        let opts = {}, swiper;

        const {apiOptions, customOptions} = carousel;

        if (apiOptions && is.object(apiOptions)) {
          opts = deepAssign(
            $data.cloneObject(this.defaults),
            apiOptions
          );
        }

        if (customOptions && is.object(customOptions)) {

          const modifiedCustomOptions = {};

          const {navHolder, lazy} = customOptions;

          if (navHolder && is.string(navHolder)) {
            modifiedCustomOptions['navigation'] = {
              prevEl: $dom.get('.is-control--prev', navHolder),
              nextEl: $dom.get('.is-control--next', navHolder)
            }
          }

          if (lazy && is.boolean(lazy)) {
            modifiedCustomOptions['preloadImages'] = false;
            modifiedCustomOptions['lazy'] = variables.defaultSwiperLazyOptions;
          }

          opts = deepAssign(
            modifiedCustomOptions,
            opts
          );

          if (customOptions.counter && is.boolean(customOptions.counter)) {
            let counterUpdate;

            swiper = new Swiper(carousel.target, Object.assign(opts, {
              init: false
            }));

            swiper.on('init', () => {
              counterUpdate = creator.counterUpdater(swiper);
            });

            swiper.on('slideChange', () => {
              counterUpdate && counterUpdate();
            });

            swiper = swiper.init()
          } else {
            swiper = new Swiper(carousel.target, opts)
          }
          if (navHolder && swiper.isEnd) {
            $dom.addClass($dom.get(navHolder), 'is-hide');
          }
          swiper.on('slideChange', () => {
            toggleText().closeAll();
          });
        }

        return is.undefined(swiper) ? new Swiper(carousel.target, opts) : swiper;
      });
    }

    return this.carousels;
  }

  destroy() {
    return this.carousels.filter(carousel => {
      carousel.destroy();
      return false;
    })
  }
}