import createContactsMap from '../components/map';

export default class Footer {

  constructor(options = {}) {

    this.defaults = {
      selectors: {
        root: '#js-footer'
      },
    };
  }

  initContactsMap() {
    window.FrontAPI = {};
    window.FrontAPI.createContactsMap = createContactsMap;
  }

  destroy() {

  }
  init() {
    this.initContactsMap();
  }
}
