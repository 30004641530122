import toggleText from "../components/toggleText";
import variables from "../../variables";
import reused from "./reused";

const controlsSelector = `.js-reviews-carousel-controls${(variables.windowWidth >= variables.breakpoints.lg) ? '' : '-mobile'}`;

export default [
  {
    target: '.js-reviews-carousel',
    apiOptions: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      pagination: {
        el: `${controlsSelector} .js-reviews-carousel-pagination`,
        type: 'fraction'
      },
      scrollbar: {
        el: `${controlsSelector} .js-reviews-carousel-scrollbar`,
        dragSize: 'auto',
        draggable: false
      },
      on: {
        slideChangeTransitionStart() {
          toggleText().closeAll();
        }
      },
      breakpoints: {
        1200: {
          slidesPerView: 2
        }
      }
    },
    customOptions: {
      navHolder: controlsSelector
    }
  },
  reused.instagramCarousel
]