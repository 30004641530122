import {$dom} from '../../helpers/dom';
import is from 'is_js';

export default function lightbox(
  targetParentSelector = '.js-lightbox-wrap',
  targetTriggerSelector = '.js-lightbox-trigger'
) {

  return {
    init() {
      $dom.callAll(targetParentSelector, parent => {
        lightGallery(parent, {
          selector: targetTriggerSelector,
          download: false,
          thumbnail: parent.hasAttribute('data-lg-thumbnail'),
          thumbWidth: (is.touchDevice()) ? 100 : 180,
          thumbContHeight: (is.touchDevice()) ? 100 : 160,
          thumbMargin: 10,
          getCaptionFromTitleOrAlt: true,
          nextHtml: '<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">' +
              '<rect x="4.50049" y="5.25073" width="6.36481" height="1.0608" rx="0.530401" transform="rotate(-135 4.50049 5.25073)"/>' +
              '<rect x="5.25439" y="4.50073" width="6.36481" height="1.0608" rx="0.530401" transform="rotate(135 5.25439 4.50073)"/>' +
              '</svg>',
          prevHtml: '<svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">' +
              '<rect x="1.49951" y="4.74927" width="6.36481" height="1.0608" rx="0.530401" transform="rotate(45 1.49951 4.74927)"/>' +
              '<rect x="0.745605" y="5.49927" width="6.36481" height="1.0608" rx="0.530401" transform="rotate(-45 0.745605 5.49927)"/>' +
              '</svg>'
        })
      });
    },
    destroy() {
      $dom.callAll(targetParentSelector, parent => {
        let instance = window.lgData[parent.getAttribute('lg-uid')];

        if (is.not.undefined(instance) && is.not.null(instance)) instance.destroy(true);
      });
    }
  }
}