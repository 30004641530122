export default function createContactsMap(googleMapsApi, options) {
  const mapStyles = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    }
  ];

  this.defaults = {
    id: 'js-contacts-map',
    zoom: 16,
    center: [56.324863, 44.005881],
    disableDefaultUI: false,
    points: [
      //{ lat: 56.324863, lng: 44.005881 }
    ]
  };

  if (Array.isArray(options.points) && options.points.length > 0) {
    this.defaults.points = options.points
  } else {

    let lat = this.defaults.center[0];
    let lng = this.defaults.center[1];

    this.defaults.points.push({lat, lng})
  }

  this.options = Object.assign(this.defaults, options);

  this.center = new googleMapsApi.LatLng(this.options.center[0], this.options.center[1]);

  const map = new googleMapsApi.Map(document.getElementById(this.options.id), {
    center: this.center,
    zoom: this.options.zoom,
    styles: mapStyles,
    panControl: true,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: true,
    overviewMapControl: true,
    rotateControl: true,
    scrollwheel: false,
    disableDefaultUI: this.options.disableDefaultUI
  });
  //console.log('this.options.points',this.options.points);
  for (let i = 0; i < this.options.points.length; i++) {
    let point = this.options.points[i];
    let marker;
    //console.log(point,"point");
    if (point.iconPath !== undefined) {
      marker = new googleMapsApi.Marker({
        position: point,
        map: map,
        icon: point.iconPath
      });

    } else {
      marker = new googleMapsApi.Marker({
        position: point,
        map: map
      });
    }

    if (point.balloon) {
      const infowindow = new google.maps.InfoWindow();

      google.maps.event.addListener(marker, 'click', function() {
        infowindow.setContent(point.balloon);
        infowindow.open(map, marker);
      });
    }
  }

  /*const flightPlanCoordinates = [
    { lat: this.defaults.center[0], lng: this.defaults.center[1] },
    { lat: 55.764843, lng: 37.600477 },
    { lat: 55.764644, lng: 37.601099 },
    { lat: 55.765718, lng: 37.603106 },
    { lat: 55.765273, lng: 37.603999 }
  ];
  const flightPath = new google.maps.Polyline({
    path: flightPlanCoordinates,
    geodesic: true,
    strokeColor: '#DD7A6B',
    strokeOpacity: 1.0,
    strokeWeight: 3,
  });

  flightPath.setMap(map);*/


  return map
};